<template>
  <div style="height: 100%;">

    <l-map ref="theMap" class="map" id="theMap" :zoom="karte.zoom" :center="karte.center" :options="karte.mapOptions">

      <l-tile-layer :name="karte.selectedTile.name" :url="karte.selectedTile.url" :attribution="karte.selectedTile.attribution" layer-type="base"/>

      <l-marker v-if="userPosition!=null" :lat-lng="userPosition" tabindex="-1" :icon="userIcon"></l-marker>
      <l-marker v-if="firstLocation!=null" :lat-lng="firstLocation" :icon="getSymbolIcon('marker')"></l-marker>
      <l-marker v-if="lastLocation!=null" :lat-lng="lastLocation" :icon="getSymbolIcon('endpunkt')"></l-marker>

      <div v-if="pathPoints!=null && contents.length>0"> 

        <div v-for="(content,index) in contents" :key="index">

          <l-marker v-if="isset(content.latLng)" :lat-lng="content.latLng" :icon="getSymbolIcon('point')">
            <l-popup class="institution-popup">
              <institution-marker :content="content" v-if="content.content_type_name=='institution' || content.content_type_name=='sehenswrdigkeit'"/>
              <angebot-marker :content="content" v-if="content.content_type_name=='angebot'"/>
            </l-popup>
          </l-marker>

        </div>

        <l-polyline v-if="pathPoints!=null" :lat-lngs="pathPoints" :color="'#E5321B'"></l-polyline>

      </div>

    </l-map>
  </div>
</template>

<script>
  import Resource from '@/api/resource';
  import 'leaflet/dist/leaflet.css';
  import { latLng, icon, latLngBounds } from 'leaflet';
  import { LMap, LTileLayer, LMarker ,LPolyline, LPopup, LCircle } from "vue2-leaflet";
  import { getFieldValues, json_decode, isset } from '@/utils/helpers';
  import InstitutionMarker from '@/components/map/MarkerInstitutionContent.vue';
  import AngebotMarker from '@/components/map/MarkerAngebotContent.vue';

  const contentResource = new Resource('contents');

  export default {
    name: 'TourenMapView',
    components: {
      LPolyline,
      LTileLayer,
      LMarker,
      LPopup,
      LCircle,
      InstitutionMarker,
      AngebotMarker,
      LMap,
    },
    props: {
      height: {
        type: String,
      default: '700px',
      },
      contents: {
        type: Array,
        required: true,
      },
      collection: {
        type: Object,
        required: true,
      },
      allowUserGPSAccess: {
        type: Boolean,
      default: false,
      },
    },
    data() {
      return {
        modus: 'showMarkers',
        userPosition: null,
        userIcon: icon({
          iconUrl: '/assets/icons/person_pin.svg',
          shadowUrl: '',
          iconSize: [30, 30],/* size of the icon */
          iconAnchor: [20, 20], /* point of the icon which will correspond to marker's location */
          popupAnchor: [0, -41],
        }),
        karte: {
          modus: false,
          zoom: 16,
          center: latLng(54.35879047390532, 10.169230974009567),
          mapOptions: {
            zoomSnap: 0.5,
            zoomControl: true,
            touchZoom: true,
            dragging: true,
            doubleClickZoom: true,
            scrollWheelZoom: true,
            keyboard: true,
          },
          clusterOptions: {
            spiderfyOnMaxZoom: true,
            showCoverageOnHover: false,
          },
          selectedTile: {
           url:"https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png",
           attribution:"&copy; <a href=\"https://www.openstreetmap.org/copyright\">OpenStreetMap</a> contributors &copy; <a href=\"https://carto.com/attributions\">CARTO</a>"
         },
       },
     };
   },
   watch: {
    allowUserGPSAccess: {
      handler(value) {
        if(value == true){
          navigator.permissions.query({name:'geolocation'});
          this.getUserLocation();
        }
      },
      immediate: true
    },
    contents: {
      handler(val){
        this.fitMarkerBounds();
      },
      deep: true
    }
  },
  mounted() {
    var i = 0;
    window.setInterval(() => {
      i++;
      navigator.permissions.query({name:'geolocation'}).then(result => {
        /*Will return ['granted', 'prompt', 'denied']*/
        if(result.state == "granted"){
          this.getUserLocation();
        }
      });
    }, 2000);  
  },
  computed: {
    pathPoints() {
      if(this.collection!==null) {
        return this.getPathPoints(json_decode(getFieldValues(this.collection,'gpx-datei')));
      }
      return null;
    },
    firstLocation(){
      if(this.pathPoints!==null){
        return this.pathPoints[0];
      }
      return null;
    },
    lastLocation(){
      if(this.pathPoints!==null){
        return this.pathPoints[this.pathPoints.length-1];
      }
      return null;
    },
    markerData(){
      if(this.addressObj!=null && Object.prototype.hasOwnProperty.call(this.addressObj,'latitude') && this.addressObj.latitude!=null && Object.prototype.hasOwnProperty.call(this.addressObj,'longitude') && this.addressObj.longitude!=null && Object.prototype.hasOwnProperty.call(this.addressObj,'street') && this.addressObj.street!=null && Object.prototype.hasOwnProperty.call(this.addressObj,'zipcode') && this.addressObj.zipcode!=null && Object.prototype.hasOwnProperty.call(this.addressObj,'city') && this.addressObj.city!=null){

        var mapMarker = {lat:this.addressObj.latitude, long:this.addressObj.longitude, street:this.addressObj.street, zipcode:this.addressObj.zipcode, city:this.addressObj.city, latLng:latLng(this.addressObj.latitude,this.addressObj.longitude)}; 
        return mapMarker;
      }
      return null;
    },
  },
  methods: {
    isset,
    fitMarkerBounds(){
     if(this.pathPoints!=null && this.pathPoints.length>2){
       var bounds = [...this.pathPoints];
       for(var j = 0; j<this.contents.length; j++){
        if(this.contents[j].latLng !== null){
          bounds.push(this.contents[j].latLng);
        }
      }
      var temp = latLngBounds(bounds);
      if(this.$refs.hasOwnProperty('theMap')){
        this.$refs.theMap.fitBounds(temp, {
          padding: [50, 50]
        });
      }
    }
  },
  getMarkerIcon(index){
    if(index == 0){
      return this.getSymbolIcon('marker');
    }
    else if(index == (this.contents.length-1)){
      return this.getSymbolIcon('marker');
    }
    return this.getSymbolIcon('point');
  },
  getSymbolIcon(elem){
    var customIcon = null;
    if(elem=='marker'){
      customIcon = icon({
        iconUrl: '/assets/icons/marker.svg',
        shadowUrl: '',
        iconSize: [40, 40],/* size of the icon */
        iconAnchor: [19, 35], /* point of the icon which will correspond to marker's location */
        popupAnchor: [0, -35],
      });
    }
    else if(elem=='endpunkt'){
      customIcon = icon({
        iconUrl: '/assets/icons/gps_fixed.svg',
        shadowUrl: '',
          iconSize: [30, 30],/* size of the icon */
          iconAnchor: [15, 15], /* point of the icon which will correspond to marker's location */
        popupAnchor: [7, 20],
      });
    }
    else{
      customIcon = icon({
        iconUrl: '/assets/icons/marker-point.svg',
        shadowUrl: '',
          iconSize: [30, 30],/* size of the icon */
          iconAnchor: [15, 15], /* point of the icon which will correspond to marker's location */
        popupAnchor: [2, -10],
      });
    }
    return customIcon;
  },
  getUserLocation() {
    navigator.geolocation.getCurrentPosition( position => {   
     this.userPosition=latLng(position.coords.latitude, position.coords.longitude);
   },       
   error =>
   {      
    console.log(error);
  });   
  },
  getLatLngForCoordinates(coordinates){
    return latLng(coordinates[1],coordinates[0]);
  },
  hasAddress(content){
    var adresse = getFieldValues(content,'adresse');
    if(adresse!=null){
      return true;
    }
    return false;
  },
  getPathPoints(tour){
    if(tour !== null){
      var latlngs = [];
      for(var i = 0; i<tour.length; i++){
        latlngs.push(latLng(tour[i].lat[0],tour[i].lon[0]));
      }
      return latlngs;
    }
  },
},
};
</script>
<style lang="scss" scoped>

  .switchTourBtn{
    position: absolute;
    z-index: 1001;
    top: 10px;
    right: 10px;
    height: 60px;

    .btn{
      display: block;
      text-align: center;
      vertical-align: middle;
      border:none;
      background-color: #fff;
      border-radius: 0px;
      padding: 6px 7px;

      &:first-child {
        border: 2px solid #ccc;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        border-bottom: none;
      }

      &:last-child {
        border: 2px solid #ccc;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        border-top: 1px solid #ccc;
      }

      &:hover{
        background-color:#f4f4f4;
      }

      img{
        height:20px;
      }

    }

  }

  .leaflet-container .leaflet-marker-pane img{
    -webkit-filter: drop-shadow( 3px 3px 4px rgba(0, 0, 0, .5));
    filter: drop-shadow( 3px 3px 4px rgba(0, 0, 0, .5));
  }

</style>

