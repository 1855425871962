<template>
	<div class="marker-institution-content">
		<router-link :to="{ name: 'LocationView', params: {id: content.id } }">

			<img class="logo" v-if="logo" :src="logo"/>

			<h6 class="title mt10">{{ title }}</h6>

			<div class="card-detail__features mt10">

				<div v-if="adresse" class="location">
					<i class="material-icons material-icons-round mr5">place</i>
					<span>{{adresse}}</span>
				</div>

			</div>

			<router-link class="btn btn-sm mt20 mb10 show-more-btn" :to="{ name: 'LocationView', params: {id: content.id } }">
				Mehr Infos
			</router-link>

		</router-link>
	</div>
</template>
<script>

	import { getFieldValues, json_decode } from '@/utils/helpers';

	export default {
		name: "MarkerInstitutionContent",
		props: {
			content:{
				type:Object,
			default: null,
				required: true
			}
		},
		data() {
			return {
			};
		},
		computed:{
			title(){
				if(this.content!=null){
					var title = this.content.title;
					return title.length>30?title.substring(0,30)+' ...':title;
				}
				return "";
			},
			logo(){
				var logo = getFieldValues(this.content,'logo');
				/*for the old data we've imported we'll need to add a leading /*/
				if(logo != null){
					logo = json_decode(logo);
					if(typeof logo === "object"){
						if(logo.path.indexOf('https')!==-1){
							return logo.path;
						}
						else{
							return this.$backendUrl+logo.path;            
						}        
					}
					else{
						if(logo.indexOf('https')!==-1){
							return logo;
						}
						else{
							logo = logo.charAt(0)!="/"?"/"+logo:logo;
							return this.$backendUrl+logo;
						}
					}
				}
				return null;
			},
			addressObj(){
				return getFieldValues(this.content,'adresse');
			},
			adresse() {
				if (this.addressObj != null) {
					let addressParts = [];

					if (this.addressObj.street) {
						addressParts.push(this.addressObj.street);
					}

					if (this.addressObj.zipcode || this.addressObj.city) {
						let zipcodeCity = [];

						if (this.addressObj.zipcode) {
							zipcodeCity.push(this.addressObj.zipcode);
						}

						if (this.addressObj.city) {
							zipcodeCity.push(this.addressObj.city);
						}

						addressParts.push(zipcodeCity.join(' '));
					}

					return addressParts.join(', ');
				}

				return '';
			},

		},
		methods:{

		},
	}

</script>
<style lang="scss" scoped>

	@import '@/scss/_variables.scss';

	.show-more-btn{
		text-align: center;
		cursor: pointer;
		background-color: #E5321B;
		color: #fff!important;
		border: 1px solid #E5321B!important;	

		&:hover{
			color: #E5321B!important;
			border: 1px solid #E5321B!important;
			background-color: transparent;
		}

	}

	.marker-institution-content{
		text-align: center;

		.logo{
			width: 80px;
			max-width: 100%;
			min-width: auto;
			margin: 0 auto;
		}

		a{
			color:#E5321B;
		}

		a:hover{
			color:#fff;
		}

		p{
			margin-top: 4px;
			text-align: center;
			vertical-align: center;
		}

		.location, .appointments{
			text-align: center;
			color: #000;
			font-size: 14px;

			i{
				vertical-align: middle;
			}
		}

		table{
			text-align: center;
			margin: 0 auto;
		}

	}
</style>
